<template>
<section class="content">
    <div class="flex justify-content-center">
    <div class="w-100 card">
        <div class="card-body">
        <div class="row">
            <div class="card-deck">
                <div class="card shadow-sm">
                    <div class="card-body">
                    <h5 class="card-title mb-1">{{dinas.name}} ({{dinas.singkatan}})</h5>
                    <p class="card-text text-wrap mb-4" style="max-width:30rem;">{{dinas.info}}</p>
                    <router-link :to="{ path: '/data-sekolah-dinas' }"
    class="btn btn-secondary">Kembali</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-1"></div>
        <div class="table-responsive">
            <table class="table table-hover" ref="tbltes">
            <thead>
            <tr>
                <th>JUDUL</th>
                <th>NOTE TAHAP TES</th>
            </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
            </table>
        </div>
        </div>
    </div>
    </div>
    <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <form role="form" @submit.prevent="submitForm">
            <div class="modal-header">
            <h5 class="modal-title">Form</h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
            <input type="hidden" name="sekolah_id" v-model="form.sekolah_id">
            <div class="row">
                <div class="col-md-4 col-xs-12 form-group">
                    <input type="hidden" name="row_id" value="" />
                    <label class="control-label">Tahun</label>
                    <input type="number" class="form-control" ref="tahun" required v-model="form.tahun" onKeyPress="if(this.value.length==4) return false;" placeholder="contoh: 2021">
                </div>
                <div class="col-md-12 form-group">
                <label class="control-label">Judul Tahap Tes</label>
                <input id="title" class="form-control" v-model="form.title" type="text" required="required" name="title" />
                <!-- <v-select :options="courierService" v-model="form.courier_service_id" :reduce="opt => opt.id" /> -->
                </div>
                <div class="col-md-12 form-group">
                <label class="control-label">Note Tahap Tes</label>
                <textarea class="form-control" v-model="form.note_tahaptes" rows="7" cols="50"></textarea>
                </div>
            </div>
            </div>
            <div class="modal-footer">
            <button type="submit" class="btn btn-primary">
                Save changes
            </button>
            <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
            >
                Close
            </button>
            </div>
        </form>
        </div>
    </div>
    </div>
</section>
<!-- /.content -->
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import $ from "jquery";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import "vue-select/dist/vue-select.css";

export default {
name: "DetailDinas",
data() {
    return {
    //   dateStart: "",
    dinas: {},
    courierService: [],
    errors: [],
    method: "",
    form: {
        sekolah_id: "",
        tahun: "",
        title: "",
        note_tahaptes: "",
    },
    };
},
components: {
    vSelect,
},
created: function () {
    // this.roles = this.$route.meta.roles;
    // console.log("load initial data", this.$route);
    this.detailDinas(this.$route.params.id);
},
methods: {
    detailDinas: function (id) {
    authFetch("/management/sekolah_dinas/" + id).then((res) => {
        res.json().then((json) => {
        this.dinas = json;
        });
    });
    },
    submitForm: function (ev) {
    const e = this.$refs;
    this.form.sekolah_id = this.$route.params.id;
    var data = Object.keys(this.form)
    .map(
        (key) =>
        encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
    )
    .join("&");
    var urlSubmit = "/management/sekolah_dinas/tes";
    if (this.method == "PUT")
    urlSubmit = "/management/sekolah_dinas/tes/" + this.form.id;
    authFetch(urlSubmit, {
    method: this.method,
    body: data,
    })
    .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
    })
    .then((js) => {
        this.errors = [];
        if (!js.success) {
        return Swal.fire(js.msg, ``, "error");
        // console.log(js.details);
        // for (var key in js.details) {
        //     if (js.details.hasOwnProperty(key)) {
        //     this.errors.push(js.details[key]);
        //     }
        // }
        // return;
        }
        this.tbl.api().ajax.reload();
        $(e.formDialog).modal("hide");
    });

    ev.preventDefault();
    },
    handleClick(e) {},
},
mounted() {
    const e = this.$refs;
    const self = this;
    this.tbl = createTable(e.tbltes, {
    title: "",
    ajax: "/management/sekolah_dinas/tes/" + this.$route.params.id,
    roles: this.$route.params.roles,
    // roles: ["read", "create", "update"],
    serverSide: true,
    frame: true,
    displayLength: 10,
    lengthChange: true,
    paramData: function (d) {},
    columns: [
        { data: "title" },
        { data: "note_tahaptes" },
    ],
    // filterBy: [2, 7],
    filter: false,
    rowCallback: function (row, data) {
    },
    buttonClick: (evt) => {
        if (evt.role == "create") {
        self.form = {};
        self.method = "POST";
        self.errors = [];
        // self.formTitle = "";
        $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
        self.form = evt.data;
        self.method = "PUT";
        self.errors = [];
        // self.formTitle = "Edit";
        $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
        // self.form = evt.data;
        console.log(evt);
        Swal.fire({
            title: "Yakin, mau hapus data?",
            icon: "question",
			text: 'Nanti datanya hilang, lho:(',
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
        }).then((result) => {
            if (result.isDenied) {
            authFetch("/management/sekolah_dinas/tes/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
            })
                .then((res) => {
                return res.json();
                })
                .then((js) => {
                this.tbl.api().ajax.reload();
                });
            }
        });
        }
    },
    });
},
};
</script>